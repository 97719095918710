@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');


body {
  //background: linear-gradient(to left, #6456b7, #6f60cc);
  //background-color:  #fafafa;
 // background-image: radial-gradient(circle farthest-corner at -25% -50%,#0d1117 36%,rgba(13,17,23,0) 48%),radial-gradient(circle farthest-corner at -25% 150%,#0d1117 45%,rgba(13,17,23,0) 89%),radial-gradient(circle farthest-corner at -25% -100%,rgba(13,17,23,0) 55%,rgba(13,17,23,.28) 65%,rgba(13,17,23,0) 68%),radial-gradient(circle farthest-corner at -33% -75%,#1f00cc 48%,rgba(131,5,49,0) 56%),radial-gradient(circle farthest-side at 0 -50%,rgba(13,17,23,0) 64%,rgba(1,2,36,.4) 69%,rgba(13,17,23,0) 81%),radial-gradient(circle farthest-corner at 0 -50%,rgba(13,17,23,0) 33%,#010c5f 51%,rgba(13,17,23,0) 72%);
  height: 100vh;
//  background-color:  #0d1117;
  //background-image: radial-gradient(circle farthest-corner at -25% -50%,#0d1117 36%,rgba(13,17,23,0) 48%),radial-gradient(circle farthest-corner at -25% 150%,#0d1117 45%,rgba(13,17,23,0) 89%),radial-gradient(circle farthest-corner at -25% -100%,rgba(13,17,23,0) 55%,rgba(13,17,23,.28) 65%,rgba(13,17,23,0) 68%),radial-gradient(circle farthest-corner at -33% -75%,#1f00cc 48%,rgba(131,5,49,0) 56%),radial-gradient(circle farthest-side at 0 -50%,rgba(13,17,23,0) 64%,rgba(1,2,36,.4) 69%,rgba(13,17,23,0) 81%),radial-gradient(circle farthest-corner at 0 -50%,rgba(13,17,23,0) 33%,#010c5f 51%,rgba(13,17,23,0) 72%);

}

/* // Simple Scroll */
::-webkit-scrollbar {
  width: 10px;
  // height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background:rgba(55,65,81,255);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(107,114,128,255);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, .9);
}



.bg-gradient {
  // background-color:  #0d1117;
  // background-image: 
  // radial-gradient(circle farthest-corner at -25% -50%, #0d1117 36%, rgba(13, 17, 23, 0) 48%), 
  // radial-gradient(circle farthest-corner at -25% 150%, #0d1117 45%, rgba(13, 17, 23, 0) 89%), 
  // radial-gradient(circle farthest-corner at -25% -100%, rgba(13, 17, 23, 0) 55%, rgba(13, 17, 23, 0.28) 65%, rgba(13, 17, 23, 0) 68%), 
  // radial-gradient(circle farthest-corner at -33% -75%, #5d00ff 48%, rgba(131, 5, 49, 0) 56%), 
  // radial-gradient(circle farthest-side at 0 -50%, rgba(13, 17, 23, 0) 64%, rgba(1, 2, 36, 0.4) 69%, rgba(13, 17, 23, 0) 81%), 
  // radial-gradient(circle farthest-corner at 0 -50%, rgba(13, 17, 23, 0) 33%, #06015f 51%, rgba(13, 17, 23, 0) 72%);

  // background-image: 
  // radial-gradient(circle farthest-corner at -25% -50%,#0d1117 36%,rgba(13,17,23,0) 48%),
  // radial-gradient(circle farthest-corner at -25% 150%,#0d1117 45%,rgba(13,17,23,0) 89%),
  // radial-gradient(circle farthest-corner at -25% -100%,rgba(13,17,23,0) 55%,rgba(13,17,23,.28) 65%,rgba(13,17,23,0) 68%),
  // radial-gradient(circle farthest-corner at -33% -75%,#1f00cc 48%,rgba(131,5,49,0) 56%),
  // radial-gradient(circle farthest-side at 0 -50%,rgba(13,17,23,0) 64%,rgba(1,2,36,.4) 69%,rgba(13,17,23,0) 81%),
  // radial-gradient(circle farthest-corner at 0 -50%,rgba(13,17,23,0) 33%,#010c5f 51%,rgba(13,17,23,0) 72%);

 border-bottom-right-radius: 70px;
 border-bottom-left-radius: 70px;

padding-bottom: 250px;

//min-height: 690px;
//smooth transition 
background-size: 110vw 150vh;

transition: all 0.5s ease-in-out;
animation: bg 8s ;

animation-fill-mode: forwards;


}

//animation where backgroudn size goes from  110vw 110vh to 110vw 130vh in 5 seconds smoothly 
@keyframes bg {
  0% {
    background-size: 110vw 150vh;
  }
  100% {
    background-size: 110vw 180vh;
  }
}

//set the animation to start with delay of 1 second and run for 5 seconds



.App {
  text-align: center;
  font-family: 'Inter', sans-serif;
  background-color:  #0d1117;
  background-image: 
  radial-gradient(circle farthest-corner at -25% -50%, #0d1117 36%, rgba(13, 17, 23, 0) 48%), 
  radial-gradient(circle farthest-corner at -25% 150%, #0d1117 45%, rgba(13, 17, 23, 0) 89%), 
  radial-gradient(circle farthest-corner at -25% -100%, rgba(13, 17, 23, 0) 55%, rgba(13, 17, 23, 0.28) 65%, rgba(13, 17, 23, 0) 68%), 
  radial-gradient(circle farthest-corner at -33% -75%, #5d00ff 48%, rgba(131, 5, 49, 0) 56%), 
  radial-gradient(circle farthest-side at 0 -50%, rgba(13, 17, 23, 0) 64%, rgba(1, 2, 36, 0.4) 69%, rgba(13, 17, 23, 0) 81%), 
  radial-gradient(circle farthest-corner at 0 -50%, rgba(13, 17, 23, 0) 33%, #06015f 51%, rgba(13, 17, 23, 0) 72%);

  background-image: 
  radial-gradient(circle farthest-corner at -25% -50%,#0d1117 36%,rgba(13,17,23,0) 48%),
  radial-gradient(circle farthest-corner at -25% 150%,#0d1117 45%,rgba(13,17,23,0) 89%),
  radial-gradient(circle farthest-corner at -25% -100%,rgba(13,17,23,0) 55%,rgba(13,17,23,.28) 65%,rgba(13,17,23,0) 68%),
  radial-gradient(circle farthest-corner at -33% -75%,#1f00cc 48%,rgba(131,5,49,0) 56%),
  radial-gradient(circle farthest-side at 0 -50%,rgba(13,17,23,0) 64%,rgba(1,2,36,.4) 69%,rgba(13,17,23,0) 81%),
  radial-gradient(circle farthest-corner at 0 -50%,rgba(13,17,23,0) 33%,#010c5f 51%,rgba(13,17,23,0) 72%);
  // padding-bottom: 300px;
// background-color: rgba(245, 248, 253, 1);

// padding-bottom: 100px;
}

.text-gradient__indigo-red {
  padding-right: 0.05em;
  background-image: linear-gradient(207deg, #e7004d 23%,#4400ff 95%);
 // background-image: linear-gradient(210deg, #001ce7 23%, #ff00bc 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: saturate(1.5);
}

.category-button{
 // color: #081e4a;
 //color: rgb(64, 64, 64);
 background: white;
    color: #404040;
  //border: solid 2px #4040401c;
    margin: 10px 10px;
    padding: 14px 45px;
 border-radius: 7px;
}
.category-button:hover{
  background: #534898a1;
  color: rgb(238, 242, 249);
  font-weight: 500;
}
.category-button:focus{
 background: #534898;
  color: rgb(238, 242, 249);
  font-weight: 500;
}
.category-text:hover:focus:active {
  color: rgb(238, 242, 249);

}

.midjourney { 
    font-family: Montserrat,sans-serif; 
    font-family: Plus Jakarta Sans,sans-serif;
} 


svg { 
    display: block; 
    vertical-align: middle;
} 

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 52px;
  margin-top: -10px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: linear-gradient(to left, #6456b7, #6f60cc);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.css-cclel-MuiSvgIcon-root,.css-pmepm3{
  width: 18px !important;
  height: 18px !important;
}





.caption {
    bottom: 0 !important;
    width: 100% !important;
    height: 50px;
    padding: 8px 0px 5px !important;
    display: flex !important;
    border-radius: 0px !important;

p {
  font-size: 11px !important;
}
  }

  .caption-item svg {
    height: 18px !important;
    width: 18px !important;
    margin-bottom: 5px !important;
  }

  .caption-item {
    margin-top: 0px !important;
    justify-content: space-between;
  }
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Set an appropriate height */
  }
  

  /* Global styles (CSS or SCSS) */

.download-popup-overlay {
  // position: fixed;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the overlay appears above other content */
}

.download-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 400px;
}

.loader {
  margin-top: 20px;
}
span{
  margin: auto;
  // padding: 30px;
}
.close-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #e7004d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-button:hover {
  background-color: #c2003b;
}
